import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import SipAPI from '../../../../apis/sipApi';
import { orgTypes } from '../../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectUserRole, originalCompanyOrgType } from '../../../../reducers/auth';
import {
    selectAlternateIdEnabled,
    selectEnforceDeviceUidUniquenessEnabled,
    selectLocationAlternateIdEnabled,
    selectEmergencyCallAssistEnabled
} from '../../../../reducers/app';
import OrgSyncSelect from './OrgSyncSelect/OrgSyncSelect';

function OrganizationForm (props) {
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        modalLoading,
        setFieldValue,
        modalErrorOptions,
        currentCompanyOrgType,
        locationAlternateIdEnabled,
        alternateDeviceUserIdEnabled,
        enforceDeviceUidUniquenessEnabled,
        modalData,
        companyFeatures,
        setOrganizationByOrgType,
        organizationByOrgType,
        setOptions,
        options,
        classes } = props;

    const [overrideDestinationTypes, setOverrideDestinationTypes] = useState([]);
    const [tabsValue, setTabsValue] = useState(0);
    const [showHorizonPrimeWarningMessage, setShowHorizonPrimeWarningMessage] = useState(false);

    useEffect(() => {
        if((modalErrorOptions && modalErrorOptions.displayTopErrorMessage) || ((errors && errors.name) && (touched && touched.name))) {
            setTabsValue(0);
        } else if (modalErrorOptions && modalErrorOptions.backendFieldError.name) {
            setTabsValue(1);
        }
    }, [touched, errors, modalErrorOptions]);
    
    useEffect(() => {
        SipAPI.getSipPeerDestinationTypes().then(response => {
            if(values.overrideDestination) {
                response.data.forEach(
                    destinationType => {
                        if(values.overrideDestination === destinationType.type) {
                            setFieldValue('overrideDestination', destinationType);
                        }
                    }
                )
            }
            setOverrideDestinationTypes(response.data);
        });
    }, []);

    function handleTabChange (event, value) {
        setTabsValue(value)
    }

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }

    function orgTypeDisable() {
        if(props.originalCompanyOrgType === 'SYSTEM' && modalData) {
            return false;
        }
        if(modalLoading || currentCompanyOrgType === orgTypes.RESELLER || currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM || modalData) {
            return true;
        }
        return false;
    }

    function handleClear() {
        if (modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) {
            modalErrorOptions.backendFieldError.name = '';
        }
    }

    useEffect(() => {
        if (values.managerDataSync) {
            values.requireLocationInfo = false;
        }
    }, [values.managerDataSync]);
    
    useEffect(() => {
        if (companyFeatures && companyFeatures.includes('HORIZON_PRIME')) {
            if (!values.horizonPrimeEnabled) {
                setShowHorizonPrimeWarningMessage(true);
            } else {
                setShowHorizonPrimeWarningMessage(false);
            }
        }
    }, [values.horizonPrimeEnabled]);

    return (
        <form className="org-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
            {modalLoading && <LinearProgress/>}
            <br/>
            {values.orgType === 'CUSTOMER' || values.orgType === 'SERVICE_PROVIDER' || values.orgType === 'RESELLER' ?
                <div>
                    <AppBar className="org-modal-app-bar" position="relative" color="default">
                        <Tabs className="tabs-bar"
                            value={tabsValue} 
                            onChange={handleTabChange} 
                            indicatorColor="primary"
                            variant="fullWidth"
                        >
                            <Tab className="tabs" id="information-header" label="Information"/>
                            <Tab className="tabs" id="configuration-header" label="Configuration"/>
                        </Tabs>
                    </AppBar>
                    <br/>
                </div>
                : ''
            }
            {tabsValue === 0 && 
                <div>
                    {modalData && values.orgType === 'CUSTOMER' && props.originalCompanyOrgType === 'SYSTEM' &&
                        <div>
                            <InputLabel htmlFor="parentOrg" id="orgs-label" shrink={true}>Parent Organization</InputLabel>
                            <div style={{ marginTop: 5 }}>
                                <OrgSyncSelect
                                    id="parentOrg"
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    organizationByOrgType={organizationByOrgType}
                                    setOrganizationByOrgType={setOrganizationByOrgType}
                                    modalData={modalData}
                                    options={options}
                                    setOptions={setOptions}
                                    {...props}
                                />
                            </div>
                            <br/>
                        </div>
                    }
                    <TextField
                        id="name"
                        label="*Name"
                        name="name"
                        type="name"
                        onChange={handleChange}
                        onKeyDown={e => {
                            if (e.key === '\\') {
                                e.preventDefault();
                            }
                        }}
                        onPaste={e => {
                            const str = e.clipboardData.getData('Text');
                            const newStr = str.replace("\\", '');
                            if (str !== newStr) {
                                e.preventDefault()
                            }
                        }}
                        value={values.name}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
                        <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
                    <br/>
                    <span htmlFor="orgType">Organization Type</span>
                    <Select
                        id="orgType"
                        name="orgType"
                        value={values.orgType}
                        onChange={(e) => {
                            handleChange(e);
                            handleClear();
                        }}
                        disabled={orgTypeDisable()}
                        error={(modalErrorOptions && !!modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.type === 'http://cirrus.redskytech.com/errors/company/invalidParent')}
                        fullWidth={true}
                    >   {/* Don't use values.orgType since it changes, need to get original data */}
                        { ((currentCompanyOrgType === orgTypes.SERVICE_PROVIDER || currentCompanyOrgType === orgTypes.RESELLER) && (!modalData || (modalData && modalData.orgType !== 'RESELLER'))) && 
                            <MenuItem id="customer" value="CUSTOMER"><em>Customer</em></MenuItem> 
                        }
                        { (!modalData || (modalData && modalData.orgType !== 'CUSTOMER')) &&
                            <MenuItem id="reseller" value="RESELLER"><em>Reseller / Business Partner</em></MenuItem>
                        }
                        {((props.originalCompanyOrgType === 'SYSTEM' && modalData && modalData.orgType === 'RESELLER') || currentCompanyOrgType === orgTypes.CURRENT_COMPANY_ORG_TYPE_SYSTEM) && 
                            <MenuItem id="serviceProvider" value="SERVICE_PROVIDER"><em>Service Provider</em></MenuItem>
                        }
                    </Select>
                    {(modalErrorOptions && !!modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.type === 'http://cirrus.redskytech.com/errors/company/invalidParent') &&
                        <span id="edit-org-invalid-parent" className="error-prompt">{modalErrorOptions.backendFieldError.name}</span>
                    }
                    <br/>
                    <br/>
                    { modalData && 
                      values.orgType === 'RESELLER' &&
                      currentCompanyOrgType === 'SYSTEM' &&
                      <div>
                        <span htmlFor="parentOrg">Parent Organization</span>
                        <OrgSyncSelect
                            id="parentOrg"
                            closeMenuOnSelect={true}
                            isMulti={false}
                            organizationByOrgType={organizationByOrgType}
                            setOrganizationByOrgType={setOrganizationByOrgType}
                            modalData={modalData}
                            options={options}
                            setOptions={setOptions}
                            orgTypesToSearch={['SERVICE_PROVIDER']}
                        />
                      </div>
                    }
                    {values.orgType === 'CUSTOMER' && !values.usageBasedLicensing && 
                        <div>
                            <span htmlFor="licenseTypes">Licensing Model</span>
                            <Select
                                id="licenseTypes"
                                name="licenseTypes"
                                value={values.licenseTypes}
                                onChange={handleChange}
                                disabled={modalLoading || (modalData && values.orgType === 'CUSTOMER')}
                                fullWidth={true}
                            >
                                <MenuItem id="subscription" value="SUBSCRIPTION"><em>Subscription</em></MenuItem>
                                <MenuItem id="rightToUse" value="RIGHT_TO_USE"><em>Perpetual</em></MenuItem>
                            </Select>
                            <br/>
                            <br/>
                        </div>
                    }
                    {values.orgType === 'SERVICE_PROVIDER' && 
                        <div>
                            <label htmlFor="usageBasedLicensing">Usage Based</label>
                            <Switch
                                id="usageBasedLicensing"
                                name="usageBasedLicensing"
                                color="primary"
                                checked={values.usageBasedLicensing}
                                onChange={handleChange}
                                value='usageBasedLicensing'
                            /> 
                        </div>
                    }
                    <TextField
                        id="externalOrgId"
                        label="External Organization ID/Key"
                        name="externalOrgId"
                        type="externalOrgId"
                        onChange={handleChange}
                        value={values.externalOrgId ? values.externalOrgId : ""}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.externalOrgId && Boolean(errors.externalOrgId)) || (errors.externalOrgId && Boolean(errors.externalOrgId.includes('max')))}/> 
                    <span id="externalOrgId-helper-text" className="error-prompt">{(touched.externalOrgId && Boolean(errors.externalOrgId)) || (errors.externalOrgId && Boolean(errors.externalOrgId.includes('max'))) ? errors.externalOrgId : ''}</span>
                    <br/>
                    <TextField
                        id="description"
                        label="Description"
                        name="description"
                        rows={5}
                        multiline={true}
                        onChange={handleChange}
                        value={values.description}
                        onBlur={handleBlur}
                        disabled={modalLoading}
                        fullWidth={true}
                        error={(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max')))}
                        variant= "outlined"/>
                        <span id="description-helper-text" className="error-prompt">{(touched.description && Boolean(errors.description)) || (errors.description && Boolean(errors.description.includes('max'))) ? errors.description : ''}</span>
                </div>
            }
            {tabsValue === 1 && 
                <div style={{ marginTop: 30 }}>
                    {values.orgType === 'CUSTOMER' && 
                        <div style={{ maxWidth: 470, margin: '0 auto' }}>
                            <span htmlFor="locationTreeType">Location Hierarchy Type</span>
                            <Select
                                id="locationTreeType"
                                name="locationTreeType"
                                value={values.managerDataSync ? 'BUILDING' : values.locationTreeType}
                                onChange={handleChange}
                                disabled={values.managerDataSync || modalData || modalLoading}
                                fullWidth={true}
                            >
                                <MenuItem id="building" value="BUILDING"><em>Building and Location</em></MenuItem>
                                <MenuItem id="simple" value="SIMPLE"><em>Location Only</em></MenuItem>
                            </Select>
                            <br/>
                            <br/>
                            <b style={{ color: '#615f57', maxWidth: 470, margin: '0 auto', display: 'block' }}>Integrations: </b>
                        </div>
                    }
                    {values.orgType === 'CUSTOMER' && 
                        <div className="flex-row">
                            <label htmlFor="managerDataSync">Manager Data Sync</label>
                            <Switch
                                id="managerDataSync"
                                name="managerDataSync"
                                color="primary"
                                checked={values.managerDataSync}
                                onChange={handleChange}
                                value='managerDataSync'
                                disabled={modalLoading}
                            /> 
                        </div>
                    }
                    {values.managerDataSync &&
                        <div style={{ margin: '0 auto', maxWidth: 400 }}>
                            <TextField
                                id="managerDataSyncKey"
                                label="Manager Data Sync Key"
                                name="managerDataSyncKey"
                                type="managerDataSyncKey"
                                onChange={handleChange}
                                value={values.managerDataSyncKey}
                                onBlur={handleBlur}
                                disabled={modalLoading}
                                fullWidth={true}
                                error={(touched.managerDataSyncKey && Boolean(errors.managerDataSyncKey)) || (errors.managerDataSyncKey && Boolean(errors.managerDataSyncKey.includes('max'))) || (modalErrorOptions && !!modalErrorOptions.backendFieldError && !!modalErrorOptions.backendFieldError.name)} />
                            {(modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.name) &&
                                <span id="manager-data-sync-backend-helper-text" className="error-prompt">{modalErrorOptions.backendFieldError.name}</span>
                            }
                            <span id="manager-data-sync-helper-text" className="error-prompt">{(touched.managerDataSyncKey && Boolean(errors.managerDataSyncKey)) || (errors.managerDataSyncKey && Boolean(errors.managerDataSyncKey.includes('max'))) ? errors.managerDataSyncKey : ''}</span>
                            <br />
                        </div>
                    }
                    {values.orgType === 'CUSTOMER' && 
                        <div className="flex-row">
                            <label htmlFor="cerEnabled" style={{ display: 'inline-block'}}>CER Location Management</label>
                            <Switch
                                id="cerEnabled"
                                name="cerEnabled" /* important! this is how handleChange finds the field to update */
                                checked={values.cerEnabled}
                                color="primary"
                                onChange={handleChange}
                                disabled={modalLoading} />
                        </div>
                    }
                    {values.orgType === 'CUSTOMER' && 
                        <div className="flex-row">
                            <label htmlFor="informaCastEnabled" style={{ display: 'inline-block'}}>InformaCast 911 Alerting</label>
                            <Switch
                                id="informaCastEnabled"
                                name="informaCastEnabled" /* important! this is how handleChange finds the field to update */
                                checked={values.informaCastEnabled}
                                color="primary"
                                onChange={handleChange}
                                disabled={modalLoading}/>
                        </div>
                    }
                    {values.orgType === 'CUSTOMER' && props.originalCompanyOrgType === 'SYSTEM' &&
                        <div>
                            <div className="flex-row">
                                <label htmlFor="horizonPrimeEnabled" style={{ display: 'inline-block'}}>Horizon Prime</label>
                                <Switch
                                    id="horizonPrimeEnabled"
                                    name="horizonPrimeEnabled" /* important! this is how handleChange finds the field to update */
                                    checked={values.horizonPrimeEnabled}
                                    color="primary"
                                    onChange={handleChange}
                                    disabled={modalLoading}/>
                            </div>
                            {showHorizonPrimeWarningMessage &&
                                <div style={{maxWidth: 400, margin: '0 auto'}}>
                                    <span style={{ color: 'red', fontWeight: 'bold' }}>WARNING:</span> By turning off the Horizon Prime integration all 9-1-1 calls originating from Locations that would have originally been routed to your private PSAP will now be routed to a Relay Center until they are rescanned.
                                </div>
                            }
                        </div>
                    }
                    {values.orgType === 'CUSTOMER' && 
                        <b style={{ color: '#615f57', maxWidth: 470 ,margin: '20px auto 0', display: 'block' }}>Features: </b>
                    }
                    {values.orgType === 'CUSTOMER' && 
                        <div className="flex-row">
                            <label htmlFor="requireLocationInfo">Require Location Info</label>
                            <Switch
                                id="requireLocationInfo"
                                name="requireLocationInfo" /* important! this is how handleChange finds the field to update */
                                checked={values.requireLocationInfo}
                                color="primary"
                                onChange={handleChange}
                                disabled={modalLoading}/>
                        </div>
                    }
                    {
                        <div className="flex-row">
                            <label htmlFor="callRecordingEnabled" style={{ display: 'inline-block' }}>Allow Emergency Call Recording</label>
                            <Switch
                                id="callRecordingEnabled"
                                name="callRecordingEnabled" /* important! this is how handleChange finds the field to update */
                                checked={values.callRecordingEnabled}
                                color="primary"
                                onChange={handleChange}
                                disabled={modalLoading}/>
                        </div>
                    }
                    {values.orgType === 'CUSTOMER' && props.originalCompanyOrgType === 'SYSTEM' &&
                        <div className="flex-row">
                            <label htmlFor="overrideDestinationToggle">Override Routing Destination</label>
                            <Switch
                                id="overrideDestinationToggle"
                                name="overrideDestinationToggle"
                                checked={values.overrideDestinationToggle}
                                onChange={handleChange}
                                color="primary"
                            />
                        </div>
                    }
                    {values.orgType === 'CUSTOMER' && props.originalCompanyOrgType === 'SYSTEM' && values.overrideDestinationToggle &&
                        <div style={{ maxWidth: 400 ,margin: '0 auto 20px' }}>
                            <Select
                                id="overrideDestination"
                                name="overrideDestination"
                                value={values.overrideDestination}
                                onChange={handleChange}
                                disabled={modalLoading}
                                input={<Input name="overrideDestination"/>}
                                inputProps={{ 'aria-label': 'Override Routing Destination Value' }}
                                fullWidth={true}
                                error={touched.overrideDestination && Boolean(errors.overrideDestination)}>
                                {overrideDestinationTypes.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        <em>{item.displayName}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    }
                    <div className="flex-row">
                        <label htmlFor="alternateDeviceUserID" style={{ display: 'inline-block' }}>Allow Alternate Device User Identification</label>
                        <Switch
                            id="alternateDeviceUserID"
                            name="alternateDeviceUserID" /* important! this is how handleChange finds the field to update */
                            color="primary"
                            checked={values.alternateDeviceUserID}
                            onChange={handleChange}
                            disabled={modalLoading || alternateDeviceUserIdEnabled || (companyFeatures && companyFeatures.includes('ALT_DEVICE_USER_ID'))}/>
                    </div>
                    <div className="flex-row">
                        <label htmlFor="alternateLocationId" style={{ display: 'inline-block'}}>Allow Alternate Location Identification</label>
                        <Switch
                            id="alternateLocationId"
                            name="alternateLocationId" /* important! this is how handleChange finds the field to update */
                            checked={values.alternateLocationId}
                            color="primary"
                            onChange={handleChange}
                            disabled={modalLoading || locationAlternateIdEnabled || (companyFeatures && companyFeatures.includes('ALT_LOCATION_ID'))}/>
                    </div>
                    {values.orgType === 'CUSTOMER' && !values.usageBasedLicensing && 
                    <div className="flex-row">
                        <label htmlFor="emergencyCallAssist" style={{ display: 'inline-block'}}>Horizon Assist</label>
                        <Switch
                            id="emergencyCallAssist"
                            name="emergencyCallAssist" /* important! this is how handleChange finds the field to update */
                            checked={values.emergencyCallAssist}
                            color="primary"
                            onChange={handleChange}
                          />
                    </div>
                    }

                    {
                        values.orgType === 'SERVICE_PROVIDER' && props.originalCompanyOrgType === 'SYSTEM' &&
                        <div className="flex-row">
                            <label htmlFor="enforceDeviceUidUniqueness">Enforce Device User ID Uniqueness</label>
                            <Switch
                                id="enforceDeviceUidUniqueness"
                                name="enforceDeviceUidUniqueness"
                                checked={values.enforceDeviceUidUniqueness}
                                onChange={handleChange}
                                color="primary"
                                disabled={modalLoading || enforceDeviceUidUniquenessEnabled || (companyFeatures && companyFeatures.includes('DEVICE_USER_ID_UNIQUENESS'))}
                            />
                        </div>
                    }
                </div>
            }
            <Grid container direction="column" justify="center" alignItems="center">
                <Button id="saveBtn"
                    type="submit"
                    disabled={modalLoading}
                    className = {classes.btn}
                    onClick={handleScroll}
                    color="primary"
                    variant="contained">Save</Button>
            </Grid>
        </form>
    );
}

const mapStateToProps = () => createStructuredSelector({
    userRole: selectUserRole(),
    alternateDeviceUserIdEnabled: selectAlternateIdEnabled(),
    locationAlternateIdEnabled: selectLocationAlternateIdEnabled(),
    emergencyCallAssistEnabled: selectEmergencyCallAssistEnabled(),
    Enabled: selectEnforceDeviceUidUniquenessEnabled(),
    originalCompanyOrgType: originalCompanyOrgType()
});

export default connect(mapStateToProps)(OrganizationForm);
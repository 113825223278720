import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HasAccess from '../../components/HasAccess/HasAccess';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTableLoadingComponent from '../../components/ReactTableLoadingComponent';
import LocationsEditMenu from '../../containers/LocationsEditMenu/LocationsEditMenu';
import { types } from '../../reducers/locations';
import { actions as AppActions, selectLocationAlternateIdEnabled, selectHorizonPrimeEnabled } from '../../reducers/app';
import { selectUserRole, originalCompanyOrgType } from '../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import NumberFormatter from '../../components/NumberFormatter/NumberFormatter';
import DetailsModalWrapper from '../../components/DetailsModalWrapper/DetailsModalWrapper';
import { selectLocationsByParentId, locationsLoading, actions as LocationsActions, makeSelectLocationPageCount } from '../../reducers/locations';

function LocationsTable(props) {
    const { locationsFromSearch,
            locationsFromApi,
            getLocationsRequest,
            userRole,
            locationPageCount,
            horizonPrimeEnabled,
            nestedTableSortColumns,
            loading,
            tabsValue,
            simple } = props;

    const [nestedPage, setNestedPage] = useState(0);
    const [nestedTablePageSize, setNestedTablePageSize] = useState(25);

    function getGeoCoords(row) {
        if ((row.original.geoCoords) && (row.original.geoCoords.latitude) && (row.original.geoCoords.longitude)) {
            return <div title={`${row.original.geoCoords.latitude}, ${row.original.geoCoords.longitude}`}>{`${row.original.geoCoords.latitude}, ${row.original.geoCoords.longitude}`}</div>;
        } else if (row.original.address && row.original.address.latitude && row.original.address.longitude) {
            return <div title={`${row.original.address.latitude}, ${row.original.address.longitude}`}>{`${row.original.address.latitude}, ${row.original.address.longitude}`}</div>;   
        } else if (row.original.normalizedGeoCoords && row.original.normalizedGeoCoords.latitude && row.original.normalizedGeoCoords.longitude) {
            return <div title={`${row.original.normalizedGeoCoords.latitude}, ${row.original.normalizedGeoCoords.longitude}`}>{`${row.original.normalizedGeoCoords.latitude}, ${row.original.normalizedGeoCoords.longitude}`}</div>;
        } else {
            return <div></div>;
        }
    }

    const locationPageCountResults = locationPageCount.toJS()[(props.buildingFlag ? props.organizationUnitId : props.company.id)];

    const handleLocation = () => {
        props.setCurrentModal(types.ADD_LOCATION, props.buildingRow.original);
    };

    function AlternateLocationIdRow(props) {
        const { row } = props;
        return row && row.original.elin ? PhoneNumber(props) : <div title={row.original.alternateId}>{row.original.alternateId}</div>;
    }

    function PhoneNumber(props) {
        const { row } = props;
        let phoneNumber = row && row.original.elin ? row.original.elin.phoneNumber : '';
        return <div title={phoneNumber}> {!isNaN(phoneNumber) ? <NumberFormatter number={phoneNumber} /> : phoneNumber}</div>;
    }

    let columns = [
        {
            Header: <div id="locations-name">Location Name</div>,
            id: "name",
            sortable: true,
            Cell: row => <div title={row.original.name}>{row.original.name}</div>
        },
        {
            Header: <div id="locations-info">Location Information</div>,
            id: "info",
            Cell: row => <div title={row.original.info}>{row.original.info}</div>
        },
        {
            Header: <div id="locations-phone-number">{props.locationAlternateIdEnabled ? 'Phone Number / Alternate ID' : 'Phone Number'}</div>,
            id: "phone-number",
            Cell: row => props.locationAlternateIdEnabled ? <AlternateLocationIdRow row={row}/> : <PhoneNumber row={row}/>
     
        },
        {
            Header: <div id="locations-details-box">Details</div>,
            width:90,
            id: "details",
            Cell: row => <DetailsModalWrapper type={types.LOCATION_DETAILS} row={row} simple={props.simple} userRole={userRole.get("name")}/>
        }

    ];

    if(props.simple || props.cerTable || (props.radioLocation === "locations" && props.tabsValue === "E911_ANYWHERE")) {
        columns = [
            {
                Header: <div id="locations-name">Location Name</div>,
                id: "name",
                sortable: true,
                Cell: row => <div title={row.original.name}>{row.original.name}</div>
            },
            {
                Header: <div id="locations-info">Location Information</div>,
                id: "info",
                Cell: row => <div title={row.original.info}>{row.original.info}</div>
            },
            {
                Header: <div id="locations-phone-number">{props.locationAlternateIdEnabled ? 'Phone Number / Alternate ID' : 'Phone Number'}</div>,
                id: "phone-number",
                Cell: row => props.locationAlternateIdEnabled ? <AlternateLocationIdRow row={row}/> : <PhoneNumber row={row}/>
            },
            {
                Header: <div id="locations-address">Address</div>,
                id: "address",
                Cell: row => <div title={ row.original.address ? row.original.address.normalizedAddress : ''}>{row.original.address ? row.original.address.normalizedAddress : ''}</div>
            },
        ];

        if (props.simple || (props.radioLocation === "locations" && props.tabsValue === "E911_ANYWHERE")) {
            columns.push(
                {
                    Header: <div id="locations-coordinates">Geodetic Coordinates</div>,
                    id: "coordinates",
                    Cell: row => getGeoCoords(row)
                }
            )
        }

        if (props.radioLocation === "locations" && props.company.locationTreeType === "BUILDING"){
            columns.push({
                Header: <div id="locations-info">Building Name</div>,
                id: "building",
                Cell: row => (
                    <Button id={`name-button-${row.rowId}`} 
                            style={{ textTransform: 'initial' }} 
                            title={row.original.addressEntityName}
                            onClick={() => {
                                row.original.userRole = userRole;
                                row.original.simple = simple;
                                //row.original.name = row.original.addressEntityName
                                props.setCurrentModal(types.BUILDING_DETAILS, row.original);
                            }}>
                            {row.original.addressEntityName}
                    </Button>
                )
            })
        }

        if (props.originalCompanyOrgType === 'SYSTEM') {
            columns.push(
                {
                    Header: <div id="backend-provider">Backend Provider</div>,
                    id: "backend-provider",
                    Cell: row => <div title={row.original.address ? row.original.address.backendProvider : ''}>{row.original.address ? row.original.address.backendProvider : ''}</div>
                }
            )
        } else {
            if (horizonPrimeEnabled) {
                columns.push(
                    {
                        Header: <div id="backend-provider">Backend Provider</div>,
                        id: "backend-provider",
                        Cell: row => <div title={row.original.address && row.original.address.backendProvider === 'HorizonPrime' ? 'Horizon Prime' : 'Standard'}>{
                            row.original.address && row.original.address.backendProvider === 'HorizonPrime' ? 'Horizon Prime' : 'Standard'
                            }</div>
                    }
                )   
            }
        }

        if (props.originalCompanyOrgType === 'SYSTEM' && props.simple) {
            columns.push(
                {
                    Header: <div id="locations-status">Status</div>,
                    id: "status",
                    Cell: row => <div title={row.original.address ? row.original.address.msagValid : ''}>{row.original.address ? row.original.address.msagValid : ''}</div>
                }
            )
        }

        columns.push({
            Header: <div id="locations-details-box">Details</div>,
            width:90,
            id: "details",
            Cell: row => <DetailsModalWrapper type={types.LOCATION_DETAILS} simple={props.simple} row={row} userRole={userRole.get("name")}/>
        });
    }

    if (props.company.locationTreeType === "SIMPLE") { 
        columns.push({
            Header: '',
            width: 65,
            id: 'edit-menu',
            Cell: row => (
                <LocationsEditMenu row={row} simple={props.simple} buildingFlag={false}/>
            )
        });
    }
    else {
        columns.push({
            Header: '',
            width: 65,
            id: 'edit-menu',
            Cell: row => (
                <LocationsEditMenu row={row} simple={false} buildingFlag={props.buildingFlag !== undefined ? props.buildingFlag : false}/>
            )
        });
    }
    let locations = locationsFromSearch ? locationsFromSearch : locationsFromApi;

    if(!locationsFromSearch && locations)
        locations = locations.toArray();


    useEffect(() => {
        if (locations && locations.length === 0 && props.page > 0 && locationPageCountResults < props.page+1 ) {
            props.setPage(props.page-1)
        } else if (locations && locations.length === 0 && nestedPage > 0 && locationPageCountResults < nestedPage+1) {
            setNestedPage(nestedPage-1);
        }
    }, [locations && locations.length === 0 && (props.page > 0 || nestedPage > 0) && (locationPageCountResults < props.page+1 || locationPageCountResults < nestedPage+1)]);

    function resetScrollInsideTable() {
        let tableBody = document.querySelector('.rt-tbody');
        tableBody.scrollTop = 0;
    }

    useEffect(() => {
        const tableBody = document.querySelector('.rt-tbody');
        const table = document.querySelector('.ReactTable');

        if (tableBody.scrollHeight > tableBody.clientHeight) {
            table.classList.add('scrollable');
        } else {
            table.classList.remove('scrollable');
        }
    }, [loading]);

    useEffect( () => {
        if (props.cerTable) {
            props.getLocationsRequest(props.company.id, "CER", props.page+1, props.pageSize, props.searchValue, props.currentSortColumn.id, props.currentSortColumn.desc ? 'DESC': 'ASC');
            props.setTableProps({
                page: props.page+1, 
                pageSize: props.pageSize, 
                sortBy: props.currentSortColumn.id, 
                direction: props.currentSortColumn.desc ? 'DESC': 'ASC'
            });
        } else if (props.simple || props.radioLocation === "locations") {
            props.getLocationsRequest(props.organizationUnitId ? props.organizationUnitId : props.company.id, props.tabsValue === 'MANAGER_DATA_SYNC' ? 'MANAGER_DATA_SYNC' : 'default', props.page+1, props.pageSize, props.searchValue, props.currentSortColumn.id, props.nestedTableCurrentSortColumn.desc ? 'DESC': 'ASC');
            props.setTableProps({
                page: props.page+1, 
                pageSize: props.pageSize, 
                sortBy: props.currentSortColumn.id, 
                direction: props.nestedTableCurrentSortColumn.desc ? 'DESC': 'ASC'
            });
        } else {
            props.getLocationsRequest(props.organizationUnitId ? props.organizationUnitId : props.company.id, props.tabsValue === 'MANAGER_DATA_SYNC' ? 'MANAGER_DATA_SYNC' : 'default', nestedPage+1, nestedTablePageSize, null, props.nestedTableCurrentSortColumn.id, props.nestedTableCurrentSortColumn.desc ? 'DESC': 'ASC');
            props.setTableProps({
                page: nestedPage+1, 
                pageSize: nestedTablePageSize, 
                sortBy: props.nestedTableCurrentSortColumn.id, 
                direction: props.nestedTableCurrentSortColumn.desc ? 'DESC': 'ASC'
            });
        }
    }, [props.currentSortColumn, props.nestedTableCurrentSortColumn, props.pageSize, nestedTablePageSize, props.page, nestedPage, props.tabsValue]);

    return (
        <div>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <HasAccess
                    permissions={["ADD_LOCATIONS"]}
                >
                    {   ((!locationsFromSearch && !props.simple && !props.company.managerDataSync) || (props.buildingFlag !== undefined && props.buildingFlag)) && props.radioLocation !== "locations" && !props.cerTable && tabsValue !== 'MANAGER_DATA_SYNC' &&
                        <Button className="button" id={props.buildingRow ? `add-button-${props.buildingRow.rowId}` : 'add-location-button'} variant="contained" color="primary" style={{marginBottom: '15px'}} onClick={handleLocation}>
                            Add Location
                        </Button>
                    } 
                </HasAccess>
            </Grid>
            <ReactTable
                resizable={false}
                sortable={false}
                className="-striped -highlight locationsListTable"
                showPaginationBottom={true}
                data={locations}
                page={(props.radioLocation === "locations" || props.cerTable || props.simple) ? props.page : nestedPage}
                pages={locationPageCountResults > 0 ? locationPageCountResults : 1}
                minRows={locations && locations.length > 0 ? 0 : 5}
                defaultPageSize={nestedTablePageSize ? nestedTablePageSize : 25}
                columns={columns}
                manual
                loading={loading}
                LoadingComponent={ReactTableLoadingComponent}
                sorted={nestedTableSortColumns ? nestedTableSortColumns : props.sortColumns}
                onSortedChange={(newSorted, column, shiftKey) => {
                    let sortedArray = props.nestedTableSortColumns ? [...props.nestedTableSortColumns] : [...props.sortColumns];
                    let currentSortColumn;
                    sortedArray.forEach( (columnObject, index) => {                    
                        if(column.id === columnObject.id) {
                            // state needs updated object reference, doesn't detect nested fields
                            currentSortColumn = {...columnObject};
                            currentSortColumn.desc = !currentSortColumn.desc;
                            sortedArray[index] = currentSortColumn;
                        }
                        else {
                            let resetColumn = {...columnObject};
                            resetColumn.desc = false;
                            sortedArray[index] = resetColumn;
                        }
                        if (props.radioLocation === "locations")
                            props.setCurrentSortColumn(currentSortColumn)
                            props.setNestedTableCurrentSortColumn ? props.setNestedTableCurrentSortColumn(currentSortColumn) : props.setCurrentSortColumn(currentSortColumn);
                            props.setNestedTableSortColumns ? props.setNestedTableSortColumns(sortedArray) : props.setSortColumns(sortedArray);
                    })
                }}
                onPageSizeChange={(pageSize) => {
                    if (props.buildingFlag) {
                        setNestedTablePageSize(pageSize);
                    } else {
                        props.setPageSize(pageSize);
                        resetScrollInsideTable();
                    }

                    props.setPage(0);
                    setNestedPage(0);
                }}
                onPageChange={(pageIndex) => {
                    if (props.buildingFlag) {
                        setNestedPage(pageIndex);
                    } else {
                        props.setPage(pageIndex);
                        resetScrollInsideTable();
                    }

                }}
                getTrProps={
                    (state, rowInfo, column) => {
                        if(rowInfo) {
                            rowInfo.rowId = 'locations-row-' + rowInfo.original.id;

                            return {
                                id: rowInfo.rowId,
                            };
                        } else {
                          return {};
                        }
                    }
                }
                />
        </div>
    );

}

const mapStateToProps = (state,ownProps) => {
    return createStructuredSelector({
        locationsFromApi: selectLocationsByParentId(ownProps.organizationUnitId ? ownProps.organizationUnitId : ownProps.company.id),
        loading: locationsLoading(ownProps.organizationUnitId ? ownProps.organizationUnitId : ownProps.company.id),
        userRole: selectUserRole(),
        locationAlternateIdEnabled: selectLocationAlternateIdEnabled(),
        horizonPrimeEnabled: selectHorizonPrimeEnabled(),
        locationPageCount: makeSelectLocationPageCount(),
        originalCompanyOrgType: originalCompanyOrgType()
    });
}

const mapDispatchToProps = dispatch => {
    return { 
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
        getLocationsRequest: (organizationUnitId, origin, page, pageSize, search, sortBy, direction) => dispatch(LocationsActions.getLocationsRequest(organizationUnitId, origin, page, pageSize, search, sortBy, direction)),
        setTableProps: (tableProps) => dispatch(AppActions.setTableProps(tableProps))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsTable);
    
import React, { useEffect } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Info from '@material-ui/icons/Info';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';

import { selectUserCompany } from '../../../../reducers/auth';
import { createStructuredSelector } from 'reselect';
import { actions, makeSelectMultipleAddressList, selectAddressIsAmbiguous, selectAddressIsAlternate } from '../../../../reducers/locations';
import AddressAndGeoComponent from '../../../../components/AddressAndGeoComponent/AddressAndGeoComponent';

function SimpleTreeTypeForm(props){
    const { values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        modalLoading,
        setFieldValue,
        setFieldTouched,
        handleOnChange,
        modalErrorMessage,
        multipleAddresses,
        isAlternate,
        isAmbiguous,
        addressIsAlternate,
        addressIsAmbiguous,
        setAddressIsAlternate,
        multipleAddressList,
        overrideOrganizationNameToggle,
        setOverrideOrganizationNameToggle,
        locationAlternateIdEnabled,
        handleCallbackNumberOnChange,
        phoneNumberToggle,
        setPhoneNumberToggle,
        locationLicenseDeficit,
        setErrors,
        setTouched,
        modalErrorOptions,
        setAddressIsAlternateAction,
        classes } = props;

    function handleScroll() {
        const modal = document.querySelector('.modal div:last-of-type > div div:last-of-type');
        if (modal) {
            modal.scrollTo(0, 0);
        }
    }
    function handleOverrideOrganizationNameToggle() {
        setOverrideOrganizationNameToggle(!overrideOrganizationNameToggle);
    }

    function handleLocationAlternateIdRadioButtonChange(e) {
        setPhoneNumberToggle(e.target.value);
        // reset form data on toggle
        setErrors({});
        setTouched({});
    }

    function handleClear() {
        modalErrorOptions.backendFieldError.alternateId = '';
    }

    return <form style={{ display: 'flex', flexDirection: 'column' }} className="simple-tree-form" onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
        {modalLoading && <LinearProgress/>}
        { locationAlternateIdEnabled ? 
            <Grid container direction="row" justify="center" alignItems="center">
                <Tooltip title="If the location does not have a device with a 10-digit phone number, select 'Alternate ID', so the ID used to alternatively identify the location's device can be entered."
                >
                    <span style={{ margin: '0 5px 0 7px'}} tabIndex={0}><Info /></span>
                </Tooltip>
                <FormLabel htmlFor="idType" style={{padding: '0 10px 0 0'}}>Identification Type:</FormLabel>
                <RadioGroup id="idType" value={phoneNumberToggle} onChange={handleLocationAlternateIdRadioButtonChange} row>
                    <FormControlLabel value={"PHONE_NUMBER"} control={<Radio color="primary" />} label="Phone Number" />
                    <FormControlLabel value={"LOCATION_ALTERNATE_ID"} control={<Radio color="primary" />} label="Alternate ID"/>
                </RadioGroup>
            </Grid>:""
        }
        <TextField
            id="name"
            label="*Location Name"
            name="name"
            type="name"
            onChange={handleChange}
            value={values.name}
            onBlur={handleBlur}
            disabled={modalLoading}
            fullWidth={true}
            error={(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max')))}/> 
            <span id="name-helper-text" className="error-prompt">{(touched.name && Boolean(errors.name)) || (errors.name && Boolean(errors.name.includes('max'))) ? errors.name : ''}</span>
            <br/>
        { (!locationAlternateIdEnabled || (locationAlternateIdEnabled && phoneNumberToggle === "PHONE_NUMBER")) &&
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {locationLicenseDeficit && <Tooltip 
                    style={{ position: 'static', zIndex: 1, marginLeft: 'auto', marginBottom: -40 }} 
                    title="Your organization's license limit has been reached. Please purchase more Location licenses."
                    classes={{tooltip: classes.tooltip}}>
                    <span className={classes.icon} tabIndex={0}><Info /></span>
                </Tooltip>
                }
                <NumberFormat 
                    autoComplete="off"
                    id="phoneNumber"
                    label="Phone Number"
                    name="phoneNumber"
                    type="text"
                    onValueChange={handleOnChange}
                    value={values.phoneNumber}
                    onBlur={handleBlur}
                    disabled={modalLoading || locationLicenseDeficit}
                    className={classes.field}
                    fullWidth={true}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    format="(###) ###-####" 
                    customInput={TextField}
                    mask="_"/> 
                    <span id="phoneNumber-helper-text" className="error-prompt">{touched.phoneNumber ? errors.phoneNumber : ""}</span>
            </div>
        }
        { locationAlternateIdEnabled && phoneNumberToggle === "LOCATION_ALTERNATE_ID" &&
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {locationLicenseDeficit && <Tooltip 
                    style={{ position: 'static', zIndex: 1, marginLeft: 'auto', marginBottom: -40 }} 
                    title="Your organization's license limit has been reached. Please purchase more Location licenses."
                    classes={{tooltip: classes.tooltip}}>
                    <span className={classes.icon} tabIndex={0}><Info /></span>
                </Tooltip>
                }
                <TextField
                    id="alternateId"
                    label="Alternate ID"
                    onChange={(e) => {
                        handleChange(e);
                        handleClear();
                    }}
                    value={values.alternateId}
                    onBlur={handleBlur}
                    disabled={modalLoading || locationLicenseDeficit}
                    fullWidth={true}
                    error={(touched.alternateId && Boolean(errors.alternateId)) || (errors.alternateId && Boolean(errors.alternateId.includes('max'))) || (modalErrorOptions && !!modalErrorOptions.backendFieldError && !!modalErrorOptions.backendFieldError.alternateId)}/> 
                    <span id="alternateId-helper-text" className="error-prompt">{(touched.alternateId && Boolean(errors.alternateId)) || (errors.alternateId && Boolean(errors.alternateId.includes('max'))) ? errors.alternateId : ''}</span>
            { (modalErrorOptions && modalErrorOptions.backendFieldError && modalErrorOptions.backendFieldError.alternateId) && 
                <span id="alternateId-backend-helper-text" className="error-prompt">{modalErrorOptions.backendFieldError.alternateId}</span>
            }
            <br/>
            </div>
        }
        {
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <br/>
                <Tooltip 
                    style={{ position: 'static', zIndex: 1, marginLeft: 'auto', marginBottom: -40 }} 
                    title="A 10-digit callback number to be used in case of a dropped call." 
                    classes={{tooltip: classes.tooltip}}>
                    <span className={classes.icon} tabIndex={0}><Info /></span>
                </Tooltip>
                <NumberFormat 
                    autoComplete="off"
                    id="callbackNumber"
                    label={values.alternateId ? "*Callback Number" : "Callback Number"}
                    name="callbackNumber"
                    type="text"
                    onValueChange={handleCallbackNumberOnChange}
                    value={values.callbackNumber}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    className={classes.field}
                    fullWidth={true}
                    error={touched.callbackNumber && Boolean(errors.callbackNumber)}
                    format="(###) ###-####" 
                    customInput={TextField}
                    mask="_"/> 
                    <span id="callbackNumber-helper-text" className="error-prompt">{touched.callbackNumber ? errors.callbackNumber : ""}</span>
            </div>
       }
        <br/>
        <AddressAndGeoComponent
            {...props}
            addressIsAlternate={isAlternate ? isAlternate : addressIsAlternate}
            setAddressIsAlternate={isAlternate ? setAddressIsAlternateAction : setAddressIsAlternate}
            addressIsAmbiguous={isAmbiguous ? isAmbiguous : addressIsAmbiguous}
            multipleAddressList={multipleAddressList}
        />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Tooltip style={{ position: 'static', zIndex: 1, marginLeft: 'auto', marginBottom: -40 }} title="Room/Floor/Apt/Ste" classes={{

                    tooltip: classes.tooltip
            }}>
                <span className={classes.icon} tabIndex={0}><Info /></span>
            </Tooltip>
            <TextField
                id="info"
                label={props.company.toJS().requireLocationInfo ? "*Location Information" : "Location Information"}
                name="info"
                type="info"
                onChange={handleChange}
                className={classes.info}
                value={values.info}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.info && Boolean(errors.info)) || (errors.info && Boolean(errors.info.includes('max')))}/>
                <span id="info-helper-text" className="error-prompt">{(touched.info && Boolean(errors.info)) || (errors.info && Boolean(errors.info.includes('max'))) ? errors.info : ''}</span>
        </div>
        <Grid container direction="row" justify="flex-start" alignItems="center">
            <label htmlFor="overrideOrganizationNameToggle">Override Organization Name</label>
            <Switch
                id="overrideOrganizationNameToggle"
                name="overrideOrganizationNameToggle" /* important! this is how handleChange finds the field to update */
                color="primary"
                checked={overrideOrganizationNameToggle}
                onChange={handleOverrideOrganizationNameToggle}
                value='overrideOrganizationNameToggle'
                disabled={modalLoading}/> 
        </Grid>       
        {
            overrideOrganizationNameToggle && 
            <div>
                <TextField
                    id="overrideOrganizationName"
                    label="Organization Name"
                    name="overrideOrganizationName"
                    onChange={handleChange}
                    onKeyDown={e => {
                        if (e.key === '\\') {
                            e.preventDefault();
                        }
                    }}
                    onPaste={e => {
                        const str = e.clipboardData.getData('Text');
                        const newStr = str.replace("\\", '');
                        if (str !== newStr) {
                            e.preventDefault()
                        }
                    }}
                    value={values.overrideOrganizationName}
                    onBlur={handleBlur}
                    disabled={modalLoading}
                    fullWidth={true}
                    error={(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max')))}/>
                <span id="overrideOrganizationName-helper-text" className="error-prompt">{(touched.overrideOrganizationName && Boolean(errors.overrideOrganizationName)) || (errors.overrideOrganizationName && Boolean(errors.overrideOrganizationName.includes('max'))) ? errors.overrideOrganizationName : ''}</span>
                <br/>
            </div>
        }
        <div>
            <br/>
            <TextField
                id="linkUrl"
                label="HTML Link URL"
                name="linkUrl"
                type="linkUrl"
                variant= "outlined"
                rows={5}
                multiline={true}
                onChange={handleChange}
                value={values.linkUrl}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.linkUrl && Boolean(errors.linkUrl)) || (errors.linkUrl && Boolean(errors.linkUrl.includes('max')))}/> 
            <span id="linkUrl-helper-text" className="error-prompt">{(touched.linkUrl && Boolean(errors.linkUrl)) || (errors.linkUrl && Boolean(errors.linkUrl.includes('max'))) ? errors.linkUrl : ''}</span>
            <br/>
        </div>
        {values.linkUrl && 
        <div>
            <TextField
                id="linkName"
                label="HTML Link Name"
                name="linkName"
                type="linkName"
                onChange={handleChange}
                value={values.linkName}
                onBlur={handleBlur}
                disabled={modalLoading}
                fullWidth={true}
                error={(touched.linkName && Boolean(errors.linkName)) || (errors.linkName && Boolean(errors.linkName.includes('max')))}/> 
                <span id="linkName-helper-text" className="error-prompt">{(touched.linkName && Boolean(errors.linkName)) || (errors.linkName && Boolean(errors.linkName.includes('max'))) ? errors.linkName : ''}</span>
            <br/>
        </div>
        }
        <Grid container direction="column" justify="center" alignItems="center">
            <Button id="saveBtn"
                    type="submit" 
                    disabled={modalLoading} 
                    className = {classes.btn}
                    onClick={handleScroll}
                    color="primary" 
                    variant="contained">Save</Button>
        </Grid>
    </form>
}

const mapStateToProps = () => createStructuredSelector({
    company: selectUserCompany(),
    multipleAddresses: makeSelectMultipleAddressList(),
    isAmbiguous: selectAddressIsAmbiguous(),
    isAlternate: selectAddressIsAlternate()
});

const mapDispatchToProps = dispatch => {
    return {
        setAddressIsAlternateAction: (flag) => dispatch(actions.addressIsAlternate(flag))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleTreeTypeForm);